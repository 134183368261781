<template>
  <a-modal
    v-model:visible="repairHistoryModalVisible"
    centered
    width="50%"
    :maskClosable="false"
    :footer="null"
  >
    <a-table
      :data-source="RepairHistoryList"
      :columns="RepairHistoryModalColumns"
      :pagination="false"
      class="RepairHistory_table"
    >
      <template #repairReasons="{ record }">
        <div class="reason_box">
          <div
            v-for="item in record.rma_item_to_reason"
            :key="item.ID"
            class="reason"
          >
            {{ item.Name }}
          </div>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup name="repairTimes">
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import RepairTimesState from '@/state/RepairTimes'

const store = useStore();
const { t, locale } = useI18n({ useScope: "global" });

const { repairHistoryModalVisible, RepairHistoryList, viewId, viewRepairHistory } = RepairTimesState();

const RepairHistoryModalColumns = computed(() => {
  return [
    {
      title: t("sysAdmin.rma_id"),
      dataIndex: "RmaID",
      key: "RmaID",
      width: 150,
      align: "center",
    },
    {
      title: t("route.Date"),
      dataIndex: "created_date",
      key: "created_date",
      width: 100,
      align: "center",
    },
    {
      title: t("route.StoreName"),
      dataIndex: "created_by",
      key: "created_by",
      width: 200,
      align: "center",
    },
    {
      title: t("route.PurchaseDate"),
      dataIndex: "date_of_purchase",
      key: "date_of_purchase",
      width: 120,
      align: "center",
    },
    {
      title: t("customerStatus.repairReason"),
      dataIndex: "rma_item_to_reason",
      key: "rma_item_to_reason",
      slots: {
        customRender: "repairReasons",
      },
      align: "center",
    },
  ];
});

</script>

<style scoped lang="scss">
.RepairHistory_table {
  padding-top: 32px;

  .reason_box {
    display: flex;
    flex-wrap: wrap;

    .reason {
      margin-right: 10px;
    }
  }
}
</style>