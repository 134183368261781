import { ref } from "vue";
import { formatDate } from "@/utils/utils";
import useClientRepositories from "@/composables/useClientRepositories";

const { getRepairHistory } = useClientRepositories();
const repairHistoryModalVisible = ref(false);
const RepairHistoryList = ref([]);
const viewId = ref("");

export default function dataState() {
    const viewRepairHistory = async (id) => {
        if (id == viewId.value && RepairHistoryList.value.length > 0) {
            repairHistoryModalVisible.value = true;
            return;
        }
        viewId.value = id;
        const result = await getRepairHistory(id);
        if (result.success) {
            result.data.forEach((v) => {
                v.key = v.id + " " + v.rma_status_history_id;
                v.created_date = formatDate(v.created_date);
                if (v.rma_status_history_id > 0) v.RmaID = v.RmaID + " *";
            });
            RepairHistoryList.value = result.data;
            repairHistoryModalVisible.value = true;
        }
    };
    return {
        repairHistoryModalVisible,
        RepairHistoryList,
        viewId,
        viewRepairHistory
    };
}