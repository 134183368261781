import { ref, reactive } from "vue";
import { formatDate, calcIsWarranty } from '@/utils/utils';
import {
  GetInvoice,
  GetCustomerDetail,
  GetCustomerRecord,
  GetRepairHistory
} from "@/services/clientRepositories";
import useRmaRepositories from "@/composables/useRmaRepositories"
import ListRmaState from "@/state/ListRma"
import { orderBy } from 'lodash';

const searchInvoiceDataResult = reactive({customerInfo:{}, product:[]})
const searchCustomers = ref([])

export default function useClientRepositories() {
  const { getClientRmaDataByPage } = useRmaRepositories()
  const { state } = ListRmaState()
    
  const searchInvoice = async (invoiceNumber) => {
    var raw = JSON.stringify({ InvoiceNumber: invoiceNumber })
    await GetInvoice(raw).then((result) => {
      if (result.success) {
        searchInvoiceDataResult.product = []
        for(var j =0; j < result.orderDetailData.length; j++) {
          result.orderDetailData[j].value.map((row) => {
            var apm_invoice = result.data.value[j].apm_invoice_no
            for(var i =0; i<row.quantity; i++) {
              // var date2 = new Date(row.createdon)
              // var date1 = new Date();
              // var Difference_In_Time = date2.getTime() - date1.getTime();
              // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
              // var is_warranty = "No"
              
              // if(Difference_In_Days <= 712) {
              //   is_warranty = "Yes"
              // }
              const is_warranty = calcIsWarranty(row.createdon) ? "Yes" : "No"
              searchInvoiceDataResult.product.push({
                date: row.createdon,
                dateDisplay: formatDate(row.createdon),
                itemno: row.productname,
                amount: row.baseamount,
                avatar: "https://imgcdn.apm-monaco.cn/products-images/" + row.productname + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76",
                warranty: is_warranty,
                _customerid_value: result.data.value[j]._customerid_value,
                selected: false,
                apm_invoice_no: apm_invoice,
                ...row,
              })
            }
          })
        }
            
      } else {
        notification.error({
          message: 'Error',
          description: "Please provide an invoice"
        })
      }
    });
    return searchInvoiceDataResult;
  };

  const searchInvoiceByCustomer = async (customerId) => {
    var raw = JSON.stringify({ CustomerGUID: customerId })
    await GetInvoice(raw).then((result) => {
      if (result.success) {
        searchInvoiceDataResult.product = []
        var key = 0
        for(var i = 0; i< result.orderDetailData.length; i++) {
          result.orderDetailData[i].value.map((row, idx) => {
            // var date2 = new Date(row.createdon)
            // var date1 = new Date();
            // var Difference_In_Time = date1.getTime() - date2.getTime();
            // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            // var is_warranty = "Expired"
            // if(Difference_In_Days <= 712) {
            //   is_warranty = "Under Warranty"
            // }
            // const is_warranty = calcIsWarranty(row.createdon) ? "Under Warranty" : "Expired"
            const is_warranty = row.warranty_status === 1 ? "Under Warranty" : "Expired"
            var apm_inv = ""
            if (result.data.value[i].apm_invoice_no.indexOf(' ') > 0) {
              apm_inv = result.data.value[i].apm_invoice_no.substr(0,result.data.value[i].apm_invoice_no.indexOf(' '))
            } else {
              apm_inv = result.data.value[i].apm_invoice_no
            }
            if (row.thk_variant_code != null) {
              searchInvoiceDataResult.product.push({
                thk_country: result.data.value[i].thk_country,
                thk_type: result.data.value[i].thk_type,
                key: key++,
                date: formatDate(row.createdon),
                itemno: row.productname,
                size: row.thk_variant_code.thk_code,
                amount: row.baseamount,
                avatar: "https://imgcdn.apm-monaco.cn/products-images/" + row.productname + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76",
                warranty: is_warranty,
                isExtendWarranty: row.extended_warranty,
                order_id: apm_inv,
                selected: false,
                ...row,
              })
            }
          })
        }
  
      } else {
        notification.error({
          message: 'Error',
          description: "Error in API"
        })
      }
    });
    return orderBy(searchInvoiceDataResult.product, ['createdon'], ['desc']);
  };

  const searchCustomerDetailsByCustomerGUID = async (customerId) => {
    var raw = JSON.stringify({ guid: customerId })
    searchInvoiceDataResult.value = await GetCustomerDetail(raw).then((result) => {
      if (result.success) {
        searchInvoiceDataResult.customerInfo = result
      } else {
        notification.error({
          message: 'Error',
          description: "Please provide an invoice"
        })
      }
    });
    return searchInvoiceDataResult;
  };

  const searchCustomerDetails = async (firstname, lastname, email, phone) => {
    var raw = { firstname: "", lastname: "", mail: "", phone: "" }
    var key = ref(0)
    // if(firstname !== "") {
    //   raw.fullname = firstname
    // }
    // if (lastname !== "") {
    //   if(firstname !== "") {
    //     raw.fullname +=  " " + lastname
    //   }
    //   else {
    //     raw.fullname +=  lastname
    //   }
    // }
    raw.firstname = firstname
    raw.lastname = lastname

    if(email.value !== "") {
      raw.mail = email
    }

    if(phone !== "") {
      raw.phone = phone
    }
        
    await GetCustomerDetail(raw).then((result) => {
      if (result.success) {
        if (result.data.length > 0) {
          searchCustomers.value = []
          key.value = 0
          result.data.map((row) => {
            var fullphone = ""
            
            if(row.new_apm_phoneareacode !== null) {
              fullphone += "(" + row.new_apm_phoneareacode + ") "
            }
            if (row.mobilephone !== null) {
              fullphone += row.mobilephone
            }
            searchCustomers.value.push({
              "key": key.value,
              "fullname": row.fullname,
              "phone": row.mobilephone,
              "fullphone": fullphone,
              "emailaddress": row.emailaddress1,
              ...row,
            })
            key.value++;
          })
        }
      } else {
        notification.error({
          message: 'Error',
          description: "No matches found"
        })
      }
    });
    return searchCustomers.value;
  };

  const getClientRmaData = async (parms) => {
    const result = await getClientRmaDataByPage(parms)
    state.listRmaData = result
    state.tableTotal = result.total
  }

  const getRepairHistory = async (id) => {
    const result = await GetRepairHistory('id=' + id)
    return result
  }

  return {
    searchInvoice,
    searchCustomerDetailsByCustomerGUID,
    searchCustomerDetails,
    searchInvoiceByCustomer,
    getClientRmaData,
    getRepairHistory
  }
}